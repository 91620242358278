class SentinelMaxBands{
    constructor(
        max_week_emax,
        max_month_emax,
        max_year_emax,
        max_week_eavg,
        max_month_eavg,
        max_year_eavg,
        max_week_emin,
        max_month_emin,
        max_year_emin
    ){
       this.emin = [max_week_emin || 0, max_month_emin || 0, max_year_emin || 0];
       this.emax = [max_week_emax || 0, max_month_emax || 0, max_year_emax || 0];
       this.eavg = [max_week_eavg || 0, max_month_eavg || 0, max_year_eavg || 0];
    }
}

export default SentinelMaxBands;