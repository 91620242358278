import {
    BarChart as BarChar,
    XAxis,
    YAxis,
    CartesianGrid,
    Bar,
    Tooltip,
    ResponsiveContainer,
    Legend,
    ReferenceLine
  } from 'recharts';
  import moment from 'moment';

  function BarChart({unitOfMeasure, data, xLabel, yLabel, type, options, threshold, ...props}) {

    const renderLegend = () => {
      return (
        <p style={{textAlign:"center"}}>Data ultima rilevazione : {moment.unix(data[0].date).format('HH:mm DD/MM')}</p>
      );
    }

    //console.log(thresholdOffset, middleOffset)
    //TODO fix linear gradient
    return (
      <ResponsiveContainer  width="100%" height={500}>
        <BarChar data={data}
          barSize={20}
          margin={{ top: 70, right: 50, left: 30, bottom: 100 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            label={{ value: `${xLabel}`, angle: 0, position: 'bottom', offset: 40}}
          />
          <YAxis 
            domain={[0, threshold+threshold*0.2]}
            label={{ value: `${yLabel}`, angle: -90, position: 'insideLeft'}} 
          />
          <Tooltip />
          <Legend content={renderLegend} verticalAlign="top" height={36}/>
          <Bar 
            name = {`${unitOfMeasure}`}
            dataKey="value"
            fill="#6a8291"
            />
          <ReferenceLine y={threshold} label="Max" stroke="red" strokeDasharray="3 3" />
        </BarChar>
      </ResponsiveContainer>
    
    );
  }
  
  export default BarChart;