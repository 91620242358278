class SentinelMaxFonos{
    constructor(
        max_week_ist,
        max_month_ist,
        max_year_ist,
    ){
        this.max_week_ist = max_week_ist || 0;
        this.max_month_ist = max_month_ist || 0;
        this.max_year_ist = max_year_ist || 0;
    }
}

export default SentinelMaxFonos;