import React from "react";
import '../styles/footerStyles.css';

const Footer = () => (
  <div className="footer footerContent">
    <h1>Contatti</h1>
    <p>Email: info@ecosentinel.it</p>
  </div>
);

export default Footer;