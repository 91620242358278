import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('access_token');
    if(tokenString === undefined){
      return null;
    }else{
      const userToken = JSON.parse(tokenString);
      return userToken;
    }

  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    if('refresh_token' in userToken && userToken.refresh_token){
      localStorage.setItem('refresh_token', JSON.stringify(userToken.refresh_token));
    }
    if('access_token' in userToken && userToken.access_token){
        localStorage.setItem('access_token', JSON.stringify(userToken.access_token));
        setToken(userToken.access_token);
        return true
    }
    return false
  };

  return {
    setToken: saveToken,
    token
  }
}
