import ChartNoMax from './ChartNoMax';
import Gauge from './Gauge';
import {Row, Container, Col} from 'react-bootstrap';
import '../styles/UnitStatsStyles.css'
import  BarChart  from './BarChart';
import AreaAndPointsChartNoMax from './AreaAndPointsChartNoMax';

function CardChartPmt10({type, title, unitOfMeasure, xLabel, yLabel, threshold, data, maXes, description, ...props}){
    switch (type) {
      case "Area":
        return(
          <CardAreaChartPmt10
            title={title}
            unitOfMeasure={unitOfMeasure}
            description={description}
            xLabel={xLabel}
            yLabel={yLabel}
            threshold={threshold}
            data={data}
            maXes={maXes}
          />
        );
      case "Bar":
        return(
          <CardBarChartPmt10
            title={title}
            unitOfMeasure={unitOfMeasure}
            xLabel={xLabel}
            yLabel={yLabel}
            threshold={threshold}
            data={data}
            maXes={maXes}
          />
        )
      case "AreaAndPoints":
        return(
          <CardAreaAndPointsChartPmt10
            title={title}
            unitOfMeasure={unitOfMeasure}
            xLabel={xLabel}
            yLabel={yLabel}
            threshold={threshold}
            data={data}
            maXes={maXes}
          />
        )
      default:
        return  ( <><p>not supported chart</p></>)
    }
  }

  function CardBarChartPmt10({title, unitOfMeasure, xLabel, yLabel, threshold, data, maXes, ...props}) {
    return(
      <div className="card linechart">
        <div className="card-top">
          <h2>{title}</h2>
        </div>
        <div className="card-mid">
          <div className="data-container">
            <Container fluid>
              <Row noGutters={true}>
                <Col md={12} >
                <div className="previous-data">
                <p className="stat">Massima Intensità Rilevata</p>
                  <Gauge
                      max={Math.round(Math.max(...data.map(x=> x.value)))}
                      unitOfMeasure={unitOfMeasure}
                      threshold={threshold}
                      thresholdPerc={70}
                    />
                </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <BarChart
        data={data}
        unitOfMeasure={unitOfMeasure}
        xLabel={xLabel}
        yLabel={yLabel}
        threshold={threshold}
        />
      </div>
    );
  }

  function CardAreaAndPointsChartPmt10({title, unitOfMeasure, xLabel, yLabel, threshold, data, maXes, ...props}) {
    return(
      <div className="card linechart">
        <div className="card-top">
          <h2>{title}</h2>
        </div>
        <div className="card-mid">
          <div className="data-container">
            <Container fluid>
              <Row noGutters={true}>
                <Col md={4} xs={12} >
                <div className="previous-data">
                <p className="stat">Media Settimanale:</p>
                  <Gauge
                      max={maXes[0]}
                      unitOfMeasure={unitOfMeasure}
                      threshold={threshold}
                      thresholdPerc={70}
                    />
                </div>
                </Col>
                <Col md={4} xs={12}>
                  <div className="previous-data">
                  <p className="stat">Media Mensile:</p>
                    <Gauge
                      max={maXes[1]}
                      unitOfMeasure={unitOfMeasure}
                      threshold={threshold}
                      thresholdPerc={70}
                    />
                  </div>
                </Col>
                <Col md={4} xs={12}>
                  <div className="previous-data">
                  <p className="stat">Media Annuale:</p>
                    <Gauge
                      max={maXes[2]}
                      unitOfMeasure={unitOfMeasure}
                      threshold={threshold}
                      thresholdPerc={70}
                      timeInterval="Annuale"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <AreaAndPointsChartNoMax
          data={data}
          unitOfMeasure={unitOfMeasure}
          xLabel={xLabel}
          yLabel={yLabel}
          threshold={threshold}
        />
      </div>
    );
  }

  function CardAreaChartPmt10({title, unitOfMeasure, description, xLabel, yLabel, threshold, data, maXes, ...props}) {
    return(
      <div className="card linechart">
        <div className="card-top">
          <h2>{title}</h2>
        </div>
        <div className="card-mid">
          <div className="data-container">
            <Container fluid>
              <Row noGutters={true}>
                <Col md={4} xs={12} >
                <div className="previous-data">
                <p className="stat">Media Giornaliera:</p>
                  <Gauge
                      max={maXes[0]}
                      unitOfMeasure={unitOfMeasure}
                      threshold={threshold}
                      thresholdPerc={70}
                    />
                </div>
                </Col>
                <Col md={4} xs={12}>
                  <div className="previous-data">
                  <p className="stat">Spike Annuali:</p>
                    <Gauge
                      max={maXes[1]}
                      unitOfMeasure={unitOfMeasure}
                      threshold={threshold}
                      thresholdPerc={70}
                    />
                  </div>
                </Col>
                <Col md={4} xs={12}>
                  <div className="previous-data">
                  <p className="stat">Media Annuale:</p>
                    <Gauge
                      max={maXes[2]}
                      unitOfMeasure={unitOfMeasure}
                      threshold={threshold}
                      thresholdPerc={70}
                      timeInterval="Annuale"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
          {description? <p style={{textAlign:"center", marginBottom:0}}>{description}</p>: <></>}
        <ChartNoMax
          data={data}
          unitOfMeasure={unitOfMeasure}
          xLabel={xLabel}
          yLabel={yLabel}
          threshold={threshold}
        />
      </div>
    );
  }

  export default CardChartPmt10;
