import NavHeader from "./NavHeader";
import CardChart from "./CardChart";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import API from "../api/API";
import Sentinel from "../api/Sentinel";
import "../styles/UnitStatsStyles.css";
import ChartData from "../api/ChartData";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router";
import CardChartPmt from "./CardChartPmt";
import CardChartPmt10 from "./CardChartPmt10";
import CardChartSisma from "./CardChartSisma";
//TODO for now only 1 day pick
const _DAY = 1;

function UnitStats() {
	const location = useLocation();
	const [sentinel, setSentinel] = useState(new Sentinel());
	const [chartData, setChartData] = useState(null);
	const redirect = useHistory();
	const search = location.search;

	async function getChartData(sentinel, days) {
		const bands = await API.getSentinelBands(sentinel, days).catch((err) =>
			console.log(err)
		);
		const pmi = await API.getSentinelPMI(sentinel, days).catch((err) =>
			console.log(err)
		);
		const lastAuxs = await API.getSentinelLastAuxs(sentinel).catch((err) =>
			console.log(err)
		);
		const auxs = await API.getSentinelAuxs(sentinel, days).catch((err) =>
			console.log(err)
		);
		const fonos = await API.getSentinelFonos(sentinel, days).catch((err) =>
			console.log(err)
		);
		const sismas = await API.getSentinelSismas(sentinel, days).catch((err) =>
			console.log(err)
		);
		const maxBands = await API.getSentinelMaxBands(sentinel).catch((err) =>
			console.log(err)
		);
		const maxPmts = await API.getSentinelMaxPmts(sentinel).catch((err) =>
			console.log(err)
		);
		const maxAuxs = await API.getSentinelMaxAuxs(sentinel).catch((err) =>
			console.log(err)
		);
		const maxFonos = await API.getSentinelMaxFonos(sentinel).catch((err) =>
			console.log(err)
		);
		const maxSismas = await API.getSentinelMaxSismas(sentinel).catch((err) =>
			console.log(err)
		);
		const meteos = await API.getSentinelMeteos(sentinel, days).catch((err) =>
			console.log(err)
		);
		const lastMeteos = await API.getSentinelLastMeteos(sentinel).catch((err) =>
			console.log(err)
		);

		return new ChartData(
			bands,
			pmi,
			lastAuxs,
			auxs,
			fonos,
			meteos,
			sismas,
			maxBands,
			maxPmts,
			maxAuxs,
			maxFonos,
			maxSismas,
			lastMeteos,

		);
	}

	// for now takes only 1 day values
	useEffect(() => {
		const matricola = new URLSearchParams(search).get("matricola");

		if (matricola === "private") {
			setSentinel("private");
		} else {
			API.getSentinel(matricola)
				.then((res) => {
					setSentinel(res);

					getChartData(res, _DAY)
						.then((data) => {
							console.log(data);
							setChartData(data);
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					console.log(err);
					redirect.push("/login");
				});
		}
	}, [redirect, search]);

	//if(!location.state) return <Redirect to={{pathname: "/"}} />

	if (sentinel === "private") {
		return (
			<>
				<NavHeader location={"/unitstats"} />
				<div className="main-content">
					<Container fluid>
						<Row className="loader">
							<h1
								style={{
									padding: "300px",
									textAlign: "center",
									color: "rgb(242, 73, 73)",
								}}
							>
								PRIVATE SENTINEL
							</h1>
						</Row>
					</Container>
				</div>
			</>
		);
	}

	if (chartData) {
		let textVento = "";
		let textDeg = "";
		if (chartData.meteos.length > 1) {
			textVento = chartData.meteos[chartData.meteos.length - 1].windDirName;
			textDeg = chartData.meteos[chartData.meteos.length - 1].windDirection;
		}
		return (
			<>
				<NavHeader location={"/unitstats"} />
				<div className="main-content">
					<Container fluid>
						<h1>{sentinel.extra}</h1>
						<Row>
							{chartData.bands && chartData.bands.length > 0 ? (
								<Col xl={6} lg={12}>
									<CardChart
										type="Area"
										title="Campo Elettromagnetico Max"
										unitOfMeasure="V/m"
										xLabel="time"
										yLabel="eMax"
										threshold={6}
										maXes={chartData.maxBands.emax}
										data={chartData.bands.map((value) => {
											return {
												dataPoint: value.emax,
												date: value.date,
											};
										})}
									/>
								</Col>
							) : (
								<></>
							)}
							{chartData.bands && chartData.bands.length > 0 ? (
								<Col xl={6} lg={12}>
									<CardChart
										type="Area"
										title="Campo Elettromagnetico Medio"
										unitOfMeasure="V/m"
										xLabel="time"
										yLabel="eAvg"
										threshold={6}
										maXes={chartData.maxBands.eavg}
										data={chartData.bands.map((value) => {
											return {
												dataPoint: value.eavg,
												date: value.date,
											};
										})}
									/>
								</Col>
							) : (
								<></>
							)}
							{chartData.pmi && chartData.pmi.length > 0 ? (
								<Col xl={6} lg={12}>
									<CardChartPmt
										type="Area"
										title="Particolato PM 2.5"
										unitOfMeasure="µg/m3"
										xLabel="time"
										yLabel="PM 2.5"
										threshold={25}
										maXes={chartData.maxPmts.pmt_2_5}
										data={chartData.pmi.map((value) => {
											return {
												dataPoint: value.pmt_2_5,
												date: value.date,
											};
										})}
									/>
								</Col>
							) : (
								<></>
							)}
							{chartData.pmi && chartData.pmi.length > 0 ? (
								<Col xl={6} lg={12}>
									<CardChartPmt10
										type="Area"
										title="Particolato PM 10"
										unitOfMeasure="µg/m3"
										xLabel="time"
										yLabel="PM 10"
										threshold={50}
										maXes={chartData.maxPmts.pmt_10}
										data={chartData.pmi.map((value) => {
											return {
												dataPoint: value.pmt_10,
												date: value.date,
											};
										})}
									/>
								</Col>
							) : (
								<></>
							)}
							{chartData.meteos && chartData.meteos.length > 0 ? (
								<>
									<Col xl={6} lg={12}>
										<CardChart
											type="Area"
											title="Temperatura"
											unitOfMeasure="°C"
											xLabel="time"
											yLabel="Celsius"
                      threshold={80}
											data={chartData.meteos.map((value) => {
												return {
													dataPoint: value.temperature,
													date: value.utc,
												};
											})}
										/>
									</Col>
									<Col xl={6} lg={12}>
										<CardChart
											type="Area"
											title="Umidità"
											unitOfMeasure="%"
											xLabel="time"
											yLabel="%"
                      threshold={100}
											data={chartData.meteos.map((value) => {
												return {
													dataPoint: value.relativeHumidity,
													date: value.utc,
												};
											})}
										/>
									</Col>
									<Col xl={6} lg={12}>
										<CardChart
											type="Area"
											title="Pressione Atmosferica"
											unitOfMeasure="hPa"
											xLabel="time"
											yLabel="hPa"
                      threshold={1300}
											data={chartData.meteos.map((value) => {
												return {
													dataPoint: value.pressure,
													date: value.utc,
												};
											})}
										/>
									</Col>
									<Col xl={6} lg={12}>
										<CardChart
											type="Area"
											title="Punto di rugiada"
											unitOfMeasure="°C Td"
											xLabel="time"
											yLabel="°C Td"
                      threshold={80}
											data={chartData.meteos.map((value) => {
												return {
													dataPoint: value.dewpoint,
													date: value.utc,
												};
											})}
										/>
									</Col>


                  <Col xl={6} lg={12}>
										<CardChart
											type="Area"
											title={"Vento "+ textVento+ " "+textDeg+"°"}
											unitOfMeasure="°km/h"
											xLabel="time"
											yLabel="°km/h"
                      threshold={200}
											data={chartData.meteos.map((value) => {
												return {
													dataPoint: value.windSpeed,
													date: value.utc,
												};
											})}
										/>
									</Col>
								</>
							) : (
								<></>
							)}
							{chartData.lastAuxs && chartData.lastAuxs.band_1 ? (
								<Col xl={6} lg={12}>
									<CardChart
										type="Bar"
										title="Rumore ambientale istantaneo"
										unitOfMeasure="dB"
										xLabel="frequenze(Hz)"
										yLabel="deciBel"
										threshold={94}
										data={[
											{
												name: "63",
												value: chartData.lastAuxs.band_1,
												date: chartData.lastAuxs.utc,
											},
											{
												name: "125",
												value: chartData.lastAuxs.band_2,
												date: chartData.lastAuxs.utc,
											},
											{
												name: "250",
												value: chartData.lastAuxs.band_3,
												date: chartData.lastAuxs.utc,
											},
											{
												name: "500",
												value: chartData.lastAuxs.band_4,
												date: chartData.lastAuxs.utc,
											},
											{
												name: "1000",
												value: chartData.lastAuxs.band_5,
												date: chartData.lastAuxs.utc,
											},
											{
												name: "2000",
												value: chartData.lastAuxs.band_6,
												date: chartData.lastAuxs.utc,
											},
											{
												name: "4000",
												value: chartData.lastAuxs.band_7,
												date: chartData.lastAuxs.utc,
											},
											{
												name: "8000",
												value: chartData.lastAuxs.band_8,
												date: chartData.lastAuxs.utc,
											},
										]}
									/>
								</Col>
							) : (
								<></>
							)}
							{chartData.auxs && chartData.auxs.length > 0 ? (
								<Col xl={6} lg={12}>
									<CardChart
										type="AreaAndPoints"
										title="Rumore ambientale"
										unitOfMeasure="dB"
										xLabel="time"
										yLabel="deciBel"
										threshold={94}
										maXes={chartData.maxAuxs.auxs}
										data={chartData.auxs.map((value) => {
											const bands = [
												//value.band_1,
												value.band_2,
												value.band_3,
												value.band_4,
												value.band_5,
												value.band_6,
												value.band_7,
												value.band_8,
											];
											return {
												max: value.band_max,
												avg: bands.reduce((a, b) => a + b) / bands.length,
												date: value.utc,
											};
										})}
									/>
								</Col>
							) : (
								<></>
							)}
							{chartData.fonos && chartData.fonos.length > 0 ? (
								<Col xl={6} lg={12}>
									<CardChart
										type="Area"
										title="Rumore ambientale"
										description="Grafico relativo alla media dei valori A ponderati, nell'intervallo di tempo T=30 s"
										unitOfMeasure="dB"
										xLabel="time"
										yLabel="deciBel"
										threshold={120}
										maXes={[
											chartData.maxFonos.max_week_ist,
											chartData.maxFonos.max_month_ist,
											chartData.maxFonos.max_year_ist,
										]}
										data={chartData.fonos.map((value) => {
											return {
												dataPoint: value.ist_avg,
												date: value.utc,
											};
										})}
									/>
								</Col>
							) : (
								<></>
							)}
							{chartData.sismas && chartData.sismas.length > 0 ? (
								<Col xl={6} lg={12}>
									<CardChartSisma
										type="Area"
										title="Vibrazioni"
										threshold={40}
										description="Grafico relativo alla dominante della media dei valori -RMS velocity- composta dai 3 assi x,y,z, nell'intervallo di tempo T=300 s"
										unitOfMeasure="mm/s"
										xLabel="time"
										yLabel="RMS velocity"
										maXes={[
											chartData.maxSismas.max_week,
											chartData.maxSismas.max_month,
											chartData.maxSismas.max_year,
										]}
										data={chartData.sismas.map((value) => {
											return {
												dataPoint: value.avg,
												date: value.utc,
											};
										})}
									/>
								</Col>
							) : (
								<></>
							)}
						</Row>
					</Container>
				</div>
			</>
		);
	} else {
		return (
			<>
				<NavHeader location={"/unitstats"} />
				<div className="main-content">
					<Container fluid>
						<Row className="loader">
							<Loader
								type="Bars"
								color="#00BFFF"
								height={100}
								width={100}
								timeout={3000} //3 secs
							/>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default UnitStats;
