import Chart from "./Chart";
import Gauge from "./Gauge";
import { Row, Container, Col } from "react-bootstrap";
import "../styles/UnitStatsStyles.css";
import BarChart from "./BarChart";
import AreaAndPointsChart from "./AreaAndPointsChart";
import PolarScatterChart from "./PolarScatterChart";

function CardChart({
	type,
	title,
	unitOfMeasure,
	xLabel,
	yLabel,
	threshold,
	data,
	maXes,
	description,
	...props
}) {
	switch (type) {
		case "Area":
			return (
				<CardAreaChart
					title={title}
					unitOfMeasure={unitOfMeasure}
					description={description}
					xLabel={xLabel}
					yLabel={yLabel}
					threshold={threshold}
					data={data}
					maXes={maXes}
				/>
			);
		case "PolarScatter":
			return (
				<CardPolarScatterChart
					title={title}
					unitOfMeasure={unitOfMeasure}
					description={description}
					xLabel={xLabel}
					yLabel={yLabel}
					threshold={threshold}
					data={data}
				/>
			);
		case "Bar":
			return (
				<CardBarChart
					title={title}
					unitOfMeasure={unitOfMeasure}
					xLabel={xLabel}
					yLabel={yLabel}
					threshold={threshold}
					data={data}
					maXes={maXes}
				/>
			);
		case "AreaAndPoints":
			return (
				<CardAreaAndPointsChart
					title={title}
					unitOfMeasure={unitOfMeasure}
					xLabel={xLabel}
					yLabel={yLabel}
					threshold={threshold}
					data={data}
					maXes={maXes}
				/>
			);
		default:
			return (
				<>
					<p>not supported chart</p>
				</>
			);
	}
}
//TODO fix inline styling on description
function CardAreaChart({
	title,
	unitOfMeasure,
	description,
	xLabel,
	yLabel,
	threshold,
	data,
	maXes,
	...props
}) {
	return (
		<div className="card linechart">
			<div className="card-top">
				<h2>{title}</h2>
			</div>
			<div className="card-mid">
				<div className="data-container">
					{maXes ? (
						<Container fluid>
							<Row noGutters={true}>
								<Col md={4} xs={12}>
									<div className="previous-data">
										<p className="stat">Massimo Settimanale:</p>
										<Gauge
											max={maXes[0]}
											unitOfMeasure={unitOfMeasure}
											threshold={threshold}
											thresholdPerc={70}
										/>
									</div>
								</Col>
								<Col md={4} xs={12}>
									<div className="previous-data">
										<p className="stat">Massimo Mensile:</p>
										<Gauge
											max={maXes[1]}
											unitOfMeasure={unitOfMeasure}
											threshold={threshold}
											thresholdPerc={70}
										/>
									</div>
								</Col>
								<Col md={4} xs={12}>
									<div className="previous-data">
										<p className="stat">Massimo Annuale:</p>
										<Gauge
											max={maXes[2]}
											unitOfMeasure={unitOfMeasure}
											threshold={threshold}
											thresholdPerc={70}
											timeInterval="Annuale"
										/>
									</div>
								</Col>
							</Row>
						</Container>
					) : (
						<></>
					)}
				</div>
			</div>
			{description ? (
				<p style={{ textAlign: "center", marginBottom: 0 }}>{description}</p>
			) : (
				<></>
			)}
			<Chart
				data={data}
				unitOfMeasure={unitOfMeasure}
				xLabel={xLabel}
				yLabel={yLabel}
				threshold={threshold}
			/>
		</div>
	);
}

function CardBarChart({
	title,
	unitOfMeasure,
	xLabel,
	yLabel,
	threshold,
	data,
	maXes,
	...props
}) {
	return (
		<div className="card linechart">
			<div className="card-top">
				<h2>{title}</h2>
			</div>
			<div className="card-mid">
				<div className="data-container">
					<Container fluid>
						<Row noGutters={true}>
							<Col md={12}>
								<div className="previous-data">
									<p className="stat">Massima Intensità Rilevata</p>
									<Gauge
										max={Math.round(Math.max(...data.map((x) => x.value)))}
										unitOfMeasure={unitOfMeasure}
										threshold={threshold}
										thresholdPerc={70}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
			<BarChart
				data={data}
				unitOfMeasure={unitOfMeasure}
				xLabel={xLabel}
				yLabel={yLabel}
				threshold={threshold}
			/>
		</div>
	);
}
function CardPolarScatterChart({
	title,
	unitOfMeasure,
	xLabel,
	yLabel,
	threshold,
	data,
	maXes,
	...props
}) {
	return (
		<div className="card linechart">
			<div className="card-top">
				<h2>{title}</h2>
			</div>
			<PolarScatterChart
				data={data}
				unitOfMeasure={unitOfMeasure}
				xLabel={xLabel}
				yLabel={yLabel}
				threshold={threshold}
			/>
		</div>
	);
}

function CardAreaAndPointsChart({
	title,
	unitOfMeasure,
	xLabel,
	yLabel,
	threshold,
	data,
	maXes,
	...props
}) {
	return (
		<div className="card linechart">
			<div className="card-top">
				<h2>{title}</h2>
			</div>
			<div className="card-mid">
				<div className="data-container">
					<Container fluid>
						<Row noGutters={true}>
							<Col md={4} xs={12}>
								<div className="previous-data">
									<p className="stat">Massimo Settimanale:</p>
									<Gauge
										max={maXes[0]}
										unitOfMeasure={unitOfMeasure}
										threshold={threshold}
										thresholdPerc={70}
									/>
								</div>
							</Col>
							<Col md={4} xs={12}>
								<div className="previous-data">
									<p className="stat">Massimo Mensile:</p>
									<Gauge
										max={maXes[1]}
										unitOfMeasure={unitOfMeasure}
										threshold={threshold}
										thresholdPerc={70}
									/>
								</div>
							</Col>
							<Col md={4} xs={12}>
								<div className="previous-data">
									<p className="stat">Massimo Annuale:</p>
									<Gauge
										max={maXes[2]}
										unitOfMeasure={unitOfMeasure}
										threshold={threshold}
										thresholdPerc={70}
										timeInterval="Annuale"
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
			<AreaAndPointsChart
				data={data}
				unitOfMeasure={unitOfMeasure}
				xLabel={xLabel}
				yLabel={yLabel}
				threshold={threshold}
			/>
		</div>
	);
}
export default CardChart;
