import React from "react";

import {
  Pie,
  PieChart,
  Cell,
  Label
} from "recharts";


const Gauge = ({max, unitOfMeasure, threshold, thresholdPerc, ...props}) => {

  //=============================================================================
  if(max < threshold){
    const maxPerc = (max*thresholdPerc)/threshold;
    const data = [{value:(100-maxPerc)},{value: maxPerc}]

    const renderCustomLabel = ({ cx, cy, midAngle, outerRadius, value }) => {
      const RADIAN = Math.PI / 180;
      const radius = outerRadius + outerRadius*0.15 ;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      return (
        <text
          x={x}
          y={y}
          fill="black"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {value===maxPerc ? `${max}` : ``}
        </text>
      );
    };

    return (
      
        <PieChart
          margin={{ top:20, right: 30, left: 30, bottom: 0 }}
          width={150} height={150}
          >
          <Pie
            startAngle={0}
            endAngle={180}
            innerRadius="40%"
            data={data}
            dataKey="value"
            labelLine={false}
            label={renderCustomLabel}
            blendStroke
            isAnimationActive={false}
            
          >
            <Cell fill="grey" />
            <Cell fill="green"/>
            <Label postition="inside" value={`${unitOfMeasure}`}/>
          </Pie>
          
        </PieChart> 
    );
  }
  //============================================================================================= 
  else {
    
    const maxPerc = (max*thresholdPerc)/threshold;
    let data = [{value:(100-maxPerc)},{value:(maxPerc-thresholdPerc)},{value: thresholdPerc}]

    //console.log(data);
    if((100-maxPerc)<0){
      data=[{value:0},{value:(100-thresholdPerc)},{value: thresholdPerc}]
    }

    const renderCustomLabel = ({ cx, cy, midAngle, outerRadius, value }) => {
      const RADIAN = Math.PI / 180;
      const radius = outerRadius + outerRadius*0.15 ;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      return (
        <text
          x={x}
          y={y}
          style={{}}
          fill="black"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {(100-maxPerc)<0 ? (
              value===(100-thresholdPerc)?
              `${max}` : ``
            ) 
            : (value===(maxPerc-thresholdPerc) ? 
              `${max}` : ``)}
        </text>
      );
    };
    return(
      <PieChart
        margin={{ top:20, right: 30, left: 30, bottom: 0 }}
        width={150} height={150}
        >
        <Pie
          startAngle={0}
          endAngle={180}
          innerRadius="40%"
          data={data}
          dataKey="value"
          labelLine={false}
          label={renderCustomLabel}
          blendStroke
          isAnimationActive={false}
          
        >
          <Label postition="inside" value={`${unitOfMeasure}`} />
          <Cell fill="grey" />
          <Cell fill="red" />
          <Cell fill="green"/>
          
        </Pie>
      </PieChart>
    );
  }
  //=========================================================================
  
};



export default Gauge;