class SentinelAuxs{
    constructor(
        band_max,
        band_1,
        band_2,
        band_3,
        band_4,
        band_5,
        band_6,
        band_7,
        band_8,
        utc 
        ){

        this.band_max = band_max || 0;
        this.band_1 = band_1 || 0;
        this.band_2 = band_2 || 0;
        this.band_3 = band_3 || 0;
        this.band_4 = band_4 || 0;
        this.band_5 = band_5 || 0;
        this.band_6 = band_6 || 0;
        this.band_7 = band_7 || 0;
        this.band_8 = band_8 || 0;
        this.utc = utc || 0;
    }
}

export default SentinelAuxs;