import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import NavHeader from "../NavHeader";
import { Container } from "react-bootstrap/";

async function loginUser(credentials) {
	return fetch("https://www.ecosentinel.it/api/v1/login", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(credentials),
	}).then((data) => data.json());
}

export default function Login({ setToken }) {
	const [username, setUserName] = useState();
	const [password, setPassword] = useState();
	const [error, setError] = useState();

	const history = useHistory();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const cred_token = await loginUser({
			username,
			password,
		});
		const res = setToken(cred_token);

		if (!res) {
			//window.location.reload();
			setError("Invalid Username or Password");
		} else {
			history.push("/");
		}
	};
	return (
		<>
			<NavHeader location={"/login"} />
			<Container fluid>
				<div className="Auth-form-container">
					<form className="Auth-form" onSubmit={handleSubmit}>
						<div className="Auth-form-content">
							<h3 className="Auth-form-title">Area riservata</h3>
							<div className="form-group mt-3">
								<label>Username</label>
								<input
									type="text"
									className="form-control mt-1"
									placeholder="Inserisci username"
									onChange={(e) => setUserName(e.target.value)}
								/>
							</div>
							<div className="form-group mt-3">
								<label>Password</label>
								<input
									type="password"
									className="form-control mt-1"
									placeholder="Inserisci password"
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<div className="d-grid gap-2 mt-3">
								<button type="submit" className="btn btn-primary">
									Accedi
								</button>
							</div>
							{error ? (
								<div class="alert alert-danger mt-3" role="alert">
									{error}
								</div>
							) : null}
						</div>
					</form>
				</div>
			</Container>
		</>
	);
}

Login.propTypes = {
	setToken: PropTypes.func.isRequired,
};
