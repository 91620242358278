class SentinelBands{
    constructor(
        emin, emax, eavg, utc
    ){
       this.emin = emin || 0;
       this.emax = emax || 0;
       this.eavg = eavg || 0;
       this.date = utc || 0;
    }
}

export default SentinelBands;