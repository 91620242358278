class SentinelMaxSismas{
    constructor(
        max_week,
        max_month,
        max_year,
    ){
        this.max_week = Number(max_week).toFixed(2) || 0;
        this.max_month = Number(max_month).toFixed(2) || 0;
        this.max_year = Number(max_year).toFixed(2) || 0;
    }
}

export default SentinelMaxSismas;
