import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  Tooltip,
  ResponsiveContainer,
  Legend,
  ReferenceLine
} from 'recharts';
import moment from 'moment';
import { useState, useEffect } from 'react';

function ChartNoMax({unitOfMeasure, data, xLabel, yLabel, type, options, threshold, ...props}) {

  const [percAnimation, setPercAnimation] = useState(0);
  //TODO sistemare velocità animazione

  useEffect(() => {
    const interval = setInterval(() => {
      if(percAnimation === 100){
        setPercAnimation(0);
      } else {
        setPercAnimation(percAnimation+2);
      }

    }, 100);
    return () => clearInterval(interval);
  }, [percAnimation]);

  //random generates an id so can assign thresholds to the charts
  function makeid(length) {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
    }
    return result;
  }

  const id = makeid(5);


  return (
    <ResponsiveContainer  width="100%" height={500}>
      <AreaChart data={data}
        margin={{ top: 70, right: 50, left: 30, bottom: 100 }}>
        <defs>
        <linearGradient id={`${id+1}`} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#00e64d" />
            <stop offset={`${percAnimation}%`} stopColor="#00e64d" />
            <stop offset={`${percAnimation}%`} stopColor="#0066ff" />
            <stop offset={`${100}%`} stopColor="#0066ff" />
        </linearGradient>
        </defs>
        <XAxis
          angle={45}
          dataKey="date"
          dy={30}
          dx={30}
          tickFormatter = {(unixTime) => moment.unix(unixTime).format('HH:mm DD/MM')}
          label={{ value: `${xLabel}`, angle: 0, position: 'bottom', offset: 60}}

        />
        <YAxis domain={[0, threshold+threshold*0.2]}  label={{ value: `${yLabel}`, angle: -90, position: 'insideLeft'}} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip labelFormatter={ t => moment.unix(t).format('HH:mm:ss DD/MM')} />
        <Legend verticalAlign="top" height={36}/>
        <Area
          name = {`${unitOfMeasure}`}
          type="monotone"
          dataKey="dataPoint"
          fillOpacity={0.6}
          stroke={`url(#${id+1})`}
          />
      </AreaChart>
    </ResponsiveContainer>

  );
}

export default ChartNoMax;
