class SentinelMaxAuxs{
    constructor(
        max_week,
        max_month,
        max_year,
    ){
       this.auxs = [max_week || 0, max_month || 0, max_year || 0];
    }
}

export default SentinelMaxAuxs;