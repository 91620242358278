class SentinelSismas{
    constructor(
        // eq_min,
        // eq_max,
        // eq_avg,
        // ist_min,
        max,
        avg,
        // pond_min,
        // pond_max,
        // pond_avg,
        utc
        ){
        // this.eq_min = eq_min || 0;
        // this.eq_max = eq_max || 0;
        // this.eq_avg = eq_avg || 0;
        // this.ist_min = ist_min || 0;
        this.max = Number(max).toFixed(2) || 0;
        this.avg = Number(avg).toFixed(2) || 0;
        // this.pond_min = pond_min || 0;
        // this.pond_max = pond_max || 0;
        // this.pond_avg = pond_avg || 0;
        this.utc = utc || 0;
    }
}

export default SentinelSismas;
