import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useState } from 'react';
import Home from './components/Home';
import UnitStats from './components/UnitStats';
import About from './components/About';
import Login from "./components/Login/Login"
import useToken from './components/App/UseToken';

function App() {
   const { setToken } = useToken();
  const [modalShow, setModalShow] = useState(true);
  return (
    <Router>
      <Switch>
        <Route
          path="/login" render={() => (
            <Login setToken={setToken}/>
          )}
        />
        <Route
          path="/unitstats"
          render={() => (
            <UnitStats/>
          )}
        />
        <Route
          path="/about"
          render={() => (
            <About/>
          )}
        />
        <Route
          path="/"
          render={() => (
            <Home modalShow={modalShow} setModalShow={setModalShow} />
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
