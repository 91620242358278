import NavHeader from './NavHeader';
import React from "react";
import { Container, Row} from 'react-bootstrap/';
import infoPDF from '../info.pdf';
import AllPages from "./Pdf";
import Footer from './Footer';


export default function Home(){
  return (
    <>
      <NavHeader location={"/about"}/>
      <Container>
        <Row className="justify-content-lg-center justify-content-md-center justify-content-xs-center">
            <AllPages pdf={infoPDF} />
        </Row>
        <Footer/>
      </Container>
    </>
  );
}
