import NavHeader from './NavHeader';
import NavSubHeader from './NavSubHeader';
import Map from './Map';
import Modal from './Modal';
import { Container, Row} from 'react-bootstrap/';
import { useEffect,useState } from 'react';
import { useHistory } from 'react-router';
import useQuery from '../hooks/useQuery';


async function refreshToken() {
    let refresh_token = localStorage.getItem('refresh_token');
   return fetch('https://www.ecosentinel.it/api/v1/refresh', {
     method: 'POST',
     headers: {
       'Accept':'*/*',
       'Connection':'keep-alive',
       'Content-Type': 'application/json',
       'Authorization': refresh_token?'Bearer '+refresh_token.replace(/"/g,''):'None',
     }
   }).then(response => response.json())
.then(response => {
  if(response.access_token){
    console.log("refresh")
    //localStorage.removeItem('access_token')
    localStorage.setItem('access_token', JSON.stringify(response.access_token))
  }
})
}

export default function Home({setModalShow, modalShow, ...props}){
  const [currentMeasure, setCurrentMeasure] = useState("band");
  const query = useQuery();
  const history = useHistory();
  const [accessRes, setAccessRes] = useState([]);
  //TODO rimuovere una volta stabilito che va bene
  //aggiunta ridondanza
  if(query.get("matricola")) {
    history.push({
      pathname: `/unitstats?matricola=${query.get("matricola")}`,
    })
  }


  useEffect(() => {
    const TkUpdate = async () => {
      const access_token = await refreshToken();
    };

    TkUpdate(); // run it, run it

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  return (
    <>
      <NavHeader location={"/"}/>
      <NavSubHeader
        currentMeasure={currentMeasure}
        setCurrentMeasure={(args)=> setCurrentMeasure(args)}
        />
      <Modal
        title="Benvenuti su Ecosentinel"
        subTitle="Heatmap"
        content="La mappa indica dove sono posizionati i nostri
                  sensori di campo elettromagnetico,particolato e rumore sul territorio Italiano.
                  I colori indicano i superamenti di soglia in base al parametro selezionabile
                  in alto a sinistra. Cliccando sull'icona di un sensore nella mappa è possibile vedere
                  più nel dettaglio le misurazioni acquisite."
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Container fluid>
        <Row className="justify-content-lg-center justify-content-md-center justify-content-xs-center">
            <Map
              currentMeasure={currentMeasure} redirect={history}
            />
        </Row>
      </Container>
    </>
  );
}
