class SentinelMaxPmts{
    constructor(
        max_week_pmt_2_5,
        max_month_pmt_2_5,
        max_year_pmt_2_5,
        max_week_pmt_10,
        max_month_pmt_10,
        max_year_pmt_10,
    ){
       this.pmt_2_5 = [max_week_pmt_2_5 || 0, max_month_pmt_2_5 || 0, max_year_pmt_2_5 || 0];
       this.pmt_10 = [max_week_pmt_10 || 0, max_month_pmt_10 || 0, max_year_pmt_10 || 0];
    }
}

export default SentinelMaxPmts;