function azimutHuman(ang) {
	//can be 0 - 360
	/*
		0° — north wind (N)
		22.5° — north-northeast wind (NNE)
		45° — northeast wind (NE)
		67.5° — east-northeast wind (ENE)
		90°— east wind (E)
		112.5° — east-southeast wind (ESE)
		135° — southeast wind (SE)
		157.5° — south-southeast wind (SSE)
		180° — south wind (S)
		202.5° — south-southwest wind (SSW)
		225° — southwest wind (SW)
		247.5° — west-southwest wind (WSW)
		270° — west wind (W)
		292.5° — west-northwest wind (WNW)
		315° — northwest wind (NW)
		337.5° — north-northwest wind (NNW)
		360° — north wind (N)
		*/
	var azimuthHumans = [
		"Nord",
		"Nord-NordEst",
		"Nord-Est",
		"Est-NordEst",
		"Est",
		"Est-SudEst",
		"Sud-Est",
		"Sud-SudEst",
		"Sud",
		"Sud-SudOvest",
		"Sud-Ovest",
		"Ovest-SudOvest",
		"Ovest",
		"Ovest-SudOvest",
		"Nord-Ovest",
		"Nord-NordOvest",
		"Nord",
	];

	return azimuthHumans[Math.round(ang / 22.5)];
}

class SentinelMeteos {
	constructor(
		windDirection,
		windSpeed,
		windFixedDirection,
		pressure,
		relativeHumidity,
		temperature,
		dewpoint,
		utc
	) {
		this.windDirection = windDirection;
		this.windSpeed = windSpeed;
		this.windFixedDirection = windFixedDirection;
		this.pressure = pressure;
		this.relativeHumidity = relativeHumidity;
		this.temperature = temperature;
		this.dewpoint = dewpoint;
		this.utc = utc;
		this.windDirName = azimutHuman(windDirection);
	}
}

export default SentinelMeteos;
