import { Navbar, Nav, Button, Image } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import '../styles/navHeaderStyles.css';
import logo from '../EcoScritta.png';



const NavHeader = ({location, ...props}) => {
  const history = useHistory();
  let token = localStorage.getItem('access_token');

  const handleOnClick = () => {
    if(window.location.pathname !== "/"){
      history.push("/");
    } else {
      window.location.reload();
    }
  }



  const userDisconnect = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    window.location.reload();
  }

  return (
    <Navbar bg="light">
      <Nav className="container-fluid">
        <Navbar.Brand href="#" onClick={()=> handleOnClick()}>
          <Image
            className="Logo"
            src={logo}
            fluid
          />
        </Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Item className="htopnav">
            {location === '/about'?
              <Button variant="success" disabled>Info e Contatti</Button> :

              <Button variant="success"
                onClick={()=>history.push("/about")}
              >Info e Contatti</Button>
            }
          </Nav.Item>
          <Nav.Item className="htopnav">
            {token ?
              <Button variant="primary" disabled>Area riservata</Button> :

              <Button variant="primary"
                onClick={()=>history.push("/login")}
              >Area riservata</Button>
            }
          </Nav.Item>

          <Nav.Item className="htopnav">
            {token ?
              <Button variant="warning" onClick={()=>userDisconnect()} >Disconnetti</Button> :

              <span></span>
            }
          </Nav.Item>
          </Nav>
      </Nav>
    </Navbar>
  );

  /* navbar with search
  return (
    <Navbar bg="dark" variant="dark">
      <Nav className="container-fluid">
        <Navbar.Brand href="#" onClick={()=> handleOnClick()}>Ecosentinel</Navbar.Brand>
        <Nav.Item className="ml-auto">
          {location === "/" ?
            <>
              <Form className="d-flex">
              <FormControl
                type="search"
                placeholder="Go to location"
                className="mr-2"
                aria-label="Search"
              />
              <Button variant="outline-success">Search</Button>
              </Form>
            </> : <></>
          }
        </Nav.Item>
      </Nav>
    </Navbar>
  );
  */
};

export default NavHeader;
