import { useState, useRef, useEffect, useCallback } from "react";
import useSuperCluster from "use-supercluster";
import ReactMapGL, {
	Marker,
	FlyToInterpolator,
	GeolocateControl,
} from "react-map-gl";
import { RiSignalTowerLine } from "react-icons/ri";
import Geocoder from "react-map-gl-geocoder";
import API from "../api/API";
import { useHistory } from "react-router";
import "../styles/mapStyles.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import useCurrentWitdth from "../hooks/useCurrentWidth";
//import useToken from './App/UseToken';

const defaultViewport = {
	latitude: 41.87194,
	longitude: 12.56738,
	width: "100vw",
	height: "100vh",
	zoom: 5.5,
};

const mapAccess = {
	//process.env.REACT_APP_MAPBOX_TOKEN,
	mapboxApiAccessToken:
		"pk.eyJ1IjoiZWNvc2VudGluZWwiLCJhIjoiY2xhNWJsMmluMTh5MTNvb2pibXpjeG0yYSJ9.QhvR-yH4jCzhcUTKg7RVKg",
};

function Map({ currentMeasure, redirect, ...props }) {
	const [viewport, setViewport] = useState(defaultViewport);
	const [sentinels, setSentinels] = useState([]);
	const history = useHistory();
	const widthHook = useCurrentWitdth();
	const [width, setWidth] = useState(widthHook);

	useEffect(() => {
		API.getAllSentinels()
			.then((res) => setSentinels(res))
			.catch((err) => {
				console.log(err);
				redirect.push("/login");
			});
	}, [history, redirect]);

	useEffect(() => {
		if (widthHook !== width) {
			let newViewport = viewport;
			newViewport.width = "100vw";
			newViewport.height = "100vh";
			setWidth(widthHook);
			setViewport(newViewport);
		}
	}, [widthHook, viewport, width]);

	const mapRef = useRef();

	const geolocateControlStyle = {
		right: 20,
		top: 20,
	};

	const points = sentinels.map((sentinel) => ({
		type: "Feature",
		properties: {
			private: sentinel.private,
			cluster: false,
			sentinelId: sentinel.id,
			matricola: sentinel.matricola,
			sensorValues: sentinel.sensorValues,
		},
		geometry: {
			type: "Point",
			coordinates: [
				parseFloat(sentinel.longitude),
				parseFloat(sentinel.latitude),
			],
		},
	}));

	const bounds = mapRef.current
		? mapRef.current.getMap().getBounds().toArray().flat()
		: null;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const { clusters, supercluster } = useCallback(
		useSuperCluster({
			points,
			bounds,
			zoom: viewport.zoom,
			options: {
				radius: 80,
				maxZoom: 20,
				map: (props) => ({
					sensorId: props.sentinelId,
					sensorValues: props.sensorValues,
					maxSensorValues: props.sensorValues,
				}),
				reduce: (acc, props) => {
					acc.maxSensorValues = {
						band: Math.max(acc.maxSensorValues.band, props.sensorValues.band),
						pmt_2_5: Math.max(
							acc.maxSensorValues.pmt_2_5,
							props.sensorValues.pmt_2_5
						),
						pmt_10: Math.max(
							acc.maxSensorValues.pmt_10,
							props.sensorValues.pmt_10
						),
						aux: Math.max(acc.maxSensorValues.aux, props.sensorValues.aux),
					};
				},
			},
		}),
		[points, bounds, viewport.zoom]
	);

	const selectColorOnSensorValue = (paramName, sensorValues) => {
		//console.log(sensorValues);
		if (sensorValues) {
			let selectedValue;
			switch (paramName) {
				case "band":
					selectedValue = sensorValues.band;
					break;
				case "pmt_2_5":
					selectedValue = sensorValues.pmt_2_5;
					break;
				case "pmt_10":
					selectedValue = sensorValues.pmt_10;
					break;
				case "aux":
					selectedValue = sensorValues.aux;
					break;
				default:
					selectedValue = sensorValues.band;
					break;
			}
			switch (selectedValue) {
				case 1:
					return "#008000"; //green
				case 2:
					return "#FFFF00"; //yellow
				case 3:
					return "#FF0000"; //red
				default:
					return "#008000"; //green
			}
		} else return "#008000";
	};

	return (
		<div className="Map">
			<ReactMapGL
				{...viewport}
				maxZoom={20}
				{...mapAccess}
				onViewportChange={(newViewport) => {
					setViewport({ ...newViewport });
				}}
				ref={mapRef}
			>
				<Geocoder
					mapRef={mapRef}
					onViewportChange={(newViewport) => {
						setViewport({ ...newViewport });
					}}
					{...mapAccess}
					position="top-left"
				/>
				<GeolocateControl
					style={geolocateControlStyle}
					positionOptions={{ enableHighAccuracy: true }}
					trackUserLocation={false}
				/>

				{clusters.map((cluster) => {
					const [longitude, latitude] = cluster.geometry.coordinates;
					const { cluster: isCluster, point_count: pointCount } =
						cluster.properties;

					if (isCluster) {
						return (
							<>
								<ClusterPoint
									cluster={cluster}
									latitude={latitude}
									longitude={longitude}
									selectColorOnSensorValue={selectColorOnSensorValue}
									currentMeasure={currentMeasure}
									supercluster={supercluster}
									setViewport={setViewport}
									viewport={viewport}
									pointCount={pointCount}
								/>
							</>
						);
					}

					return (
						<>
							<SingleSentinel
								cluster={cluster}
								latitude={latitude}
								longitude={longitude}
								history={history}
								selectColorOnSensorValue={selectColorOnSensorValue}
								currentMeasure={currentMeasure}
							/>
						</>
					);
				})}
			</ReactMapGL>
		</div>
	);
}

export default Map;

function ClusterPoint({
	cluster,
	latitude,
	longitude,
	selectColorOnSensorValue,
	currentMeasure,
	supercluster,
	setViewport,
	viewport,
	pointCount,
}) {
	return (
		<Marker key={cluster.id} latitude={latitude} longitude={longitude}>
			<div
				style={{
					background: selectColorOnSensorValue(
						currentMeasure,
						cluster.properties.maxSensorValues
					),
					width: `30px`,
					height: `30px`,
					borderRadius: "50%",
					textAlign: "center",
				}}
				onClick={() => {
					const expansionZoom = Math.min(
						supercluster.getClusterExpansionZoom(cluster.id),
						20
					);
					//console.log(cluster);
					setViewport({
						...viewport,
						latitude,
						longitude,
						zoom: expansionZoom,
						transitionInterpolator: new FlyToInterpolator({
							speed: 2,
						}),
						transitionDuration: "auto",
					});
				}}
			>
				{pointCount}
			</div>
		</Marker>
	);
}

function SingleSentinel({
	cluster,
	latitude,
	longitude,
	history,
	selectColorOnSensorValue,
	currentMeasure,
}) {
	return (
		<Marker
			key={cluster.properties.sentinelId}
			latitude={latitude}
			longitude={longitude}
		>
			<RiSignalTowerLine
				onClick={() => {
					if (!cluster.properties.private) {
						history.push({
							pathname: `/unitstats`,
							search: `?matricola=${cluster.properties.matricola}`,
						});
					} else {
						history.push({
							pathname: `/unitstats`,
							search: `?matricola=private`,
						});
					}
				}}
				size={30}
				style={{
					color: selectColorOnSensorValue(
						currentMeasure,
						cluster.properties.sensorValues
					),
				}}
			/>
			<p style={{ fontSize: "0.8em" }}>{cluster.properties.matricola}</p>
		</Marker>
	);
}
