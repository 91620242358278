import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import {IoOptions} from 'react-icons/io5';
import '../styles/navSubHeaderStyles.css';

const NavSubHeader = ({currentMeasure, setCurrentMeasure, ...props}) => {

  return (
    <Navbar bg="dark"  variant="dark" expand="lg">
      <Container fluid>
        <Navbar.Brand>Seleziona Un Parametro</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"> <IoOptions></IoOptions> </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto Measures">
          <Nav.Item>
            <Button variant="outline-light"
              onClick={()=> setCurrentMeasure("band")}
              active = {currentMeasure === "band"}
            >Campo Elettromagnetico</Button>
          </Nav.Item>
          <Nav.Item>
            <Button variant="outline-light"
              onClick={()=> setCurrentMeasure("pmt_2_5")}
              active = {currentMeasure === "pmt_2_5"}
            >PM 2.5</Button>
          </Nav.Item>
          <Nav.Item>
            <Button variant="outline-light"
              onClick={()=> setCurrentMeasure("pmt_10")}
              active = {currentMeasure === "pmt_10"}
            >PM 10</Button>
          </Nav.Item>
          <Nav.Item>
            <Button variant="outline-light"
              onClick={()=> setCurrentMeasure("aux")}
              active = {currentMeasure === "aux"}
            >Inquinamento Acustico</Button>
          </Nav.Item>
        </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavSubHeader;