class SentinelPMI{
    constructor(
        pmt_2_5, pmt_10, utc
    ){
       this.pmt_2_5 = pmt_2_5 || 0;
       this.pmt_10 = pmt_10 || 0;
       this.date = utc || 0;
    }
}

export default SentinelPMI;