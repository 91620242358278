class Sentinel{
    constructor(
        id, matricola, longitude, latitude, cap, sensor_values, extra
    ){
        this.id = id;
        this.matricola = matricola;
        this.longitude = longitude;
        this.latitude = latitude;
        this.cap = cap;
        this.extra = extra;
        this.sensorValues = sensor_values;
    }
}

export default Sentinel;