import {
  Modal as Mod,
  Button
} from 'react-bootstrap';
export default function Modal({title, subTitle, content, ...props}) {
  return (
    <Mod
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Mod.Header closeButton>
        <Mod.Title id="contained-modal-title-vcenter">
          {title}
        </Mod.Title>
      </Mod.Header>
      <Mod.Body>
        <h4>{subTitle}</h4>
        <p>
          {content}
        </p>
      </Mod.Body>
      <Mod.Footer>
        <Button onClick={props.onHide}>Chiudi</Button>
      </Mod.Footer>
    </Mod>
  );
}