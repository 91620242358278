import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import useCurrentWitdth from '../hooks/useCurrentWidth';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function AllPages({pdf, ...props}) {
    const [numPages, setNumPages] = useState(null);
    const width = useCurrentWitdth();

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
  
    return (
      <Document
        file={pdf}
        options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={width>1024 ? 1024 : width}/>
        ))}
      </Document>
  );
}
