import {
	Label,
	Legend,
	PolarAngleAxis,
	PolarGrid,
	PolarRadiusAxis,
	Radar,
	RadarChart,
	ResponsiveContainer,
} from "recharts";

import "./PolarScatterChart.css";

function PolarScatterChart({
	unitOfMeasure,
	data,
	xLabel,
	yLabel,
	type,
	options,
	threshold,
	...props
}) {
	return (
		<ResponsiveContainer width="100%" height={500}>
			<RadarChart width={730} height={250} data={data}>
				<PolarGrid
					gridType="circle"
					polarAngles={[0, 45, 90, 135, 180, 225, 270, 315]}
				/>
				<PolarRadiusAxis>
					<Label value={unitOfMeasure} position="outside" />
				</PolarRadiusAxis>
				<PolarAngleAxis type="number" dataKey="direction" hide />
				<Radar
					name={unitOfMeasure}
					dataKey="intensity"
					dot
					strokeWidth={0}
					fillOpacity={0.5}
					stroke="#0066ff"
					fill="#0066ff"
				/>
			</RadarChart>
		</ResponsiveContainer>
	);
}

export default PolarScatterChart;
